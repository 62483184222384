import profile from "./assets/profile.png";
// @ts-ignore
import Typewriter from "typewriter-effect";
import React from "react";

export const Profile:React.FC = () => {
    return (
        <div className="flex">
            <div>
                <img className="w-20 md:w-32 rounded-full mr-8 md:mr-4 mt-4 md:mt-0" src={profile} alt="Zack Rowe"/>
            </div>
            <div className="leading-snug flex-grow text-2xl md:text-3xl font-light">
                <p>Hello!</p>
                <p id="site-name">My name is <span className="text-teal-400 font-bold whitespace-no-wrap">Zack Rowe</span></p>
                <div id="site-name">I'm a <span className="font-bold"><Typewriter
                    options={{
                        strings: ['Developer', 'Dad','Snowboarder', 'Dog Dad'],
                        autoStart: true,
                        loop: true,
                    }}
                /></span></div>
            </div>
        </div>
    );
}