import React from "react";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {library} from '@fortawesome/fontawesome-svg-core'
import {faTwitter, faInstagram, faGithub} from '@fortawesome/free-brands-svg-icons';
import {Link} from "./Link";

library.add(faTwitter, faInstagram, faGithub);

export const Social:React.FC = () => {
    return (
        <div className="flex">
            <SocialIcon href="https://github.com/zackrowe">
                <FontAwesomeIcon icon={["fab", "github"]}/>
            </SocialIcon>
            <SocialIcon href="https://twitter.com/zman0404">
                <FontAwesomeIcon icon={["fab", "twitter"]}/>
            </SocialIcon>
            <SocialIcon href="https://www.instagram.com/rowe.zack">
                <FontAwesomeIcon icon={["fab", "instagram"]}/>
            </SocialIcon>
        </div>
    );
}

interface SocialIconProps {
    href: string;
}

const SocialIcon:React.FC<SocialIconProps> = (props) => {
    return (
        <Link href={props.href}>
            <div className="w-8 text-center m-1 border-teal-400 border rounded-sm">
                {props.children}
            </div>
        </Link>
    )
}