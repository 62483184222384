import React from "react";

interface Props {
    className: string,
    src: string
    alt?: string,
}

export const Image: React.FC<Props> = (props) => {
    return (
        <img className={props.className} src={"/images/"+props.src} alt={props.alt}/>
    )
}