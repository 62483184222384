import React from 'react';
import './App.css';
import {Profile} from "./Profile";
import {Social} from "./Social";
import {FeaturedProject} from './FeaturedProject';

function App():React.ReactNode {
    return (
        <div className="container mx-auto mt-5 px-4 lg:px-48 xl:px-64 text-white">
            <div className="flex justify-end">
                <Social/>
            </div>
            <div className="flex">
                <Section fullWidth={true}>
                    <Profile/>
                </Section>
            </div>
            <div className="flex">
                <Section title="Projects" fullWidth={true}>
                    <FeaturedProject/>
                </Section>
            </div>
            {/*<div className="flex flex-wrap lg:-mx-5">*/}
            {/*    <Section title="Articles">*/}
            {/*        <MoreLink/>*/}
            {/*    </Section>*/}
            {/*    <Section title="Media">*/}
            {/*        <MoreLink/>*/}
            {/*    </Section>*/}
            {/*</div>*/}

        </div>
    );
}

interface SectionProps {
    children: any;
    fullWidth: boolean;
    title?: string;
}

const Section: React.FC<SectionProps> = (props) => {
    const className = props.fullWidth ? 'w-full mt-5' : 'lg:w-1/2 lg:px-5 w-full mt-5';

    return (
        <div className={className}>
            <h2 className="text-xl md:text-3xl font-medium">{props.title}</h2>
            {props.children}
            <Separator/>
        </div>
    );
}

// function MoreLink(props) {
//     return (
//         <a href={props.link} className="float-right hover:text-teal-400 font-medium">&raquo; More</a>
//     )
// }

const Separator = () => {
    return (
        <div className="flex flex-col w-full mt-8">
            <div className="w-full border-white-400 border-t mb-1">
            </div>
            <div className="w-full border-teal-400 border-t">
            </div>
        </div>
    );
}

export default App;
