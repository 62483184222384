import React from "react";

interface Props {
    href: string;
}

export const Link: React.FC<Props> = (props) => {
    return (
        <a href={props.href} target="_blank" rel="noopener noreferrer" className="font-bold hover:text-teal-400">
            {props.children}
        </a>
    );
}