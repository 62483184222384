import React from 'react';
import ReactMarkdown from 'react-markdown';
import data from './assets/data/projects.json';
import {Link} from "./Link";
import {Image} from "./Image";

//TODO:: Switch to TypeScript
export const FeaturedProject: React.FC = () => {
    return (
        <div className="flex flex-wrap">
            <div className="w-full md:w-1/3">
                <Image className="pt-4 pb-4 m-auto" src={data[0].image}/>
            </div>
            <div className="w-full md:w-2/3">
                <p className="p-0 md:pt-2 md:pr-0 md:pb-2 md:pl-8 text-justify">
                    <ReactMarkdown>{data[0].description}</ReactMarkdown>
                    <Link href={data[0].link}>Read More</Link>
                </p>
            </div>
        </div>
    )
}